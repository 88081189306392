<template>
	<div></div>
</template>

<script>
import DifferenceMixin from '@/mixins/HHmmDifferenceMixin';

export default {
	mixins: [DifferenceMixin],
	data() {
		return {
			modelName: 'iamalive',
			modelConfig: null,
			checkPeriod: 300, // seconds
			threshold: 5 // minutes
		};
	},
	computed: {
		onPuiModelsLoadedAndSaved() {
			return this.$store.state.modelsLoadedAndSaved;
		}
	},
	watch: {
		onPuiModelsLoadedAndSaved() {
			this.modelConfig = this.$store.getters.getModelByName(this.modelName);
			if (this.modelConfig) {
				this.onReady();
			}
		}
	},
	mounted() {
		this.modelConfig = this.$store.getters.getModelByName(this.modelName);
		if (this.modelConfig) {
			this.onReady();
		}
	},
	beforeDestroy() {
		clearInterval(this.counter);
	},
	methods: {
		onReady() {
			this.startTimer(this.checkPeriod);
		},
		startTimer(countValue) {
			this.count = countValue;
			this.counter = setInterval(this.timer, 1000);
			this.isAlive();
		},
		timer() {
			this.count = this.count - 1;
			if (this.count < 0) {
				clearInterval(this.counter);
			} else if (this.count === 0) {
				clearInterval(this.counter);
				if (this.$store.getters.getToken !== null) {
					this.startTimer(this.checkPeriod);
				}
			}
		},
		isAlive() {
			this.$puiRequests.getRequest(
				this.modelConfig.url.isAlive,
				null,
				(response) => {
					if (!response.data) {
						this.$puiNotify.warning(this.$t('operationsalive.warning'), '', null, true);
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		}
	}
};
</script>
